import React from 'react'

function Header() {
    return (
        <header>
            <div className='section'>
                <img src="/assets/img/joker.png" alt="rummikub joker face" />
                <h1>Rummikub Timer</h1>
            </div>

        </header>
    )
}

export default Header